import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/views/HomePage.vue'

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage,
    },
    {
        path: '/lcfc',
        name: 'HomePageLCFC',
        component: HomePage,
    },
    {
        path: '/lcfcintern',
        name: 'HomePageLCFCIntern',
        component: HomePage,
    },
]

const router = createRouter({
    history: createWebHistory('/'),
    routes,
    scrollBehavior: false,
})

export default router
